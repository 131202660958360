.tournamentCard {
  border-radius: 24px;
  border: 1px solid var(--border-colors-second);
  background: var(--text-color-contrast);
  display: flex;
  flex-direction: column;
  width: max-content;
  margin: 0 10px;
  transition: 0.3s all ease;
}

.tournamentCard h4 {
  color: var(--fill-color-contrast);
}

.tournamentCard p {
  color: var(--text-color-second);
}

.tournamentCard strong {
  font-weight: 600;
  color: var(--fill-color-contrast);
}

.tournamentCardInner {
  display: flex;
  gap: 8px;
  padding: 20px;
}

.tournamentCardImage {
  width: 60px;
  height: 60px;
  border-radius: 8px;
  position: relative;
}

.tournamentCardImageRating {
  padding: 8px;
  border-radius: 8px;
  background: var(--text-color-contrast);
  position: absolute;
  bottom: 8px;
  right: 8px;
  line-height: 0;
}

.tournamentCardImageRatingIcon path {
  fill: #e19a10;
}

.tournamentCardContent {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 8px 0;
}

.cardItem {
  display: flex;
  gap: 8px;
  align-items: center;
}

.ratingPrice {
  display: flex;
  gap: 8px;
  align-items: center;
}

.ratingPriceSeparator {
  width: 2px;
  height: 2px;
  background: var(--color-grey-50);
  border-radius: 50%;
}

.tournamentCard .joinButton {
  border-radius: 8px;
  background: var(--color-primary-10);
  padding: 14px;
  border: none;
  color: var(--text-color-primary);
  font-family: var(--jakarta-font);
  font-weight: 600;
  font-size: var(--font-size-small);
  line-height: var(--line-height-small);
  display: flex;
  justify-content: center;
  align-items: center;
}

.tournamentCard .joinButton:hover {
  background: var(--color-primary-25);
  color: var(--text-color-primary);
}

.tournamentCard .joinButton:focus-visible {
  background: var(--color-primary-10);
  color: var(--text-color-primary);
  box-shadow: none;
  border-color: none;
}

.tournamentCard .joinButton:first-child:active {
  background: var(--color-primary-10);
  color: var(--text-color-primary);
  box-shadow: none;
  border-color: none;
}

.tournamentCardFooter {
  padding: 8px;
  border-top: 1px solid var(--border-colors-second);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tournamentCardFooterContent {
  display: flex;
  gap: 8px;
}

.tournamentCardFooterGender {
  border-radius: 8px;
  background: var(--fill-color-background);
  padding: 0 8px;
  display: flex;
  align-items: center;
}

.tournamentCardFooterGender p {
  font-weight: 600;
  color: var(--text-color-second);
}

.tournamentCardUserImages {
  display: flex;
  align-items: center;
}

.tournamentCardUserImages .tournamentCardUser:not(:first-child) {
  margin-left: -8px;
}

.tournamentCardUser {
  width: 20px;
  height: 20px;
  border-radius: 40px;
  border: 1px solid var(--text-color-contrast);
}

.tournamentCardShare {
  cursor: pointer;
}
